<template>
    <div id="navitems">
        <a href="#app" id="btt">↑ TO TOP</a> 
    </div>
</template>
<style scoped>
    #btt {
        position:fixed !important;
        right:0;
        bottom:0;
        margin:15px;
        z-index: 10;
    }
    #navitems > a {
        text-decoration: none;
        color: #000;
        padding-left: 20px;
        font-family: 'Michroma', sans-serif;
        position: relative;
        text-align:right;
        user-select: none;
    }
    #navitems > a::after {
        border-radius: 1em;
        border-top: .1em solid #000;
        content: "";
        position: absolute;
        right: 100%;
        bottom: -.2em;
        left: 1.3em;
        transition: right .4s cubic-bezier(0,.5,0,1);
    }
    #navitems > a:hover::after {
        right: .1em;
    }

    @media screen and (max-width: 1700px) {
        a {
            font-size:14px;
        }
        #navitems > a:hover::after {
            right: .05em;
        }
        #navitems > a::after {
            left: 1.5em;
        }
    }
    @media screen and (max-width: 1480px) {
        a {
            font-size:12px;
        }
        #navitems > a:hover::after {
            right: .05em;
        }
        #navitems > a::after {
            left: 1.7em;
        }
    }
    @media screen and (max-width: 1300px) {
        a {
            font-size:10px;
        }
        #navitems > a:hover::after {
            right: .05em;
        }
        #navitems > a::after {
            left: 2.1em;
        }
    }
    @media screen and (max-width: 1100px) {
        a {
            font-size:10px;
        }
        #navitems > a:hover::after {
            right: .05em;
        }
        #navitems > a::after {
            left: 2.1em;
        }
    }
    @media screen and (max-width: 768px) {
        a {
            font-size:13px;
        }
        #navitems {
            padding-top:0px;
            padding-right:20px;
        }
        #navitems > a {
            padding-right:10px;
            
        }
        #navitems > a:hover::after {
            right: .7em;
        }
        #navitems > a::after {
            left: 1.5em;
        }
        #btt {
            left:0;
            right:auto;
        }
    }
    @media screen and (max-width: 500px) {
        a {
            font-size:11px;
        }
        #navitems {
            padding-right:30px;
        }
        #navitems > a {
            padding-right:0px;
        }
        #navitems > a:hover::after {
            right: 0em;
        }
        #navitems > a::after {
            left: 1.8em;
        }
    }
</style>
<script>
    export default {
        name: 'BackToTop'
    }
</script>