<script>
import BackToTop from '../components/BackToTop.vue'

export default {
  components: {
    BackToTop
  },
  metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'test'
    }
}
</script>

<template>
    <h2>Arcadia</h2>
    <BackToTop></BackToTop>
    <div class="content">
        <agile :nav-buttons="false" :dots="false" :autoplay-speed="5000" :speed="1000" autoplay :pauseOnHover="false" style="margin-top:-45px">
            <div class="slide">
                <div class="imagecontainer">
                    <div><img src="../assets/a1.png"><p class="imgcaption">Mainnet #0</p></div>
                </div>
            </div>

            <div class="slide">
                <div class="imagecontainer">
                    <div><img src="../assets/a2.png"><p class="imgcaption">Testnet #35</p></div>
                </div>
            </div>

            <div class="slide">
                <div class="imagecontainer">
                    <div><img src="../assets/a3.png"><p class="imgcaption">Testnet #13</p></div>
                </div>
            </div>

            <div class="slide">
                <div class="imagecontainer">
                    <div><img src="../assets/a4.png"><p class="imgcaption">Testnet #18</p></div>
                </div>
            </div>
            <div class="slide">
                <div class="imagecontainer">
                    <div><img src="../assets/a5.png"><p class="imgcaption">Testnet #38</p></div>
                </div>
            </div>
            <div class="slide">
                <div class="imagecontainer">
                    <div><img src="../assets/a6.png"><p class="imgcaption">Testnet #24</p></div>
                </div>
            </div>
        </agile><br><br><br>
        <h1 class="lv2" style="text-align:center;margin-top:-40px"><a href="#process">To process</a>&nbsp;&nbsp;&nbsp;<a href="#features">To features</a>&nbsp;&nbsp;&nbsp;<a href="#evolution">To evolution</a></h1><br>
        <h1>About the project</h1><br>
        <p><i>Arcadia</i> is a homage to the fridge-worthy landscape art of our youth. Childhood techniques of creating art are re-imagined by the scribbles of a nostalgic, computer-assisted mind.</p><br>
        <p><i>Arcadia</i> is a long-form <a href="https://en.wikipedia.org/wiki/Generative_art" target="_blank">generative art</a> 
        series hosted on <a href="https://www.artblocks.io/project/358" target="_blank">Art Blocks</a>. The algorithm for the artwork takes a "seed" value - 
        a large combination of numbers and letters - and then uses that to compute shapes, colors, and scribbles for the final output.
        There is no human intervention when an output is generated; the art is created solely by a computer. 
        With this generation method, the possible outputs are almost endless and no two outputs will look the same. The project was created 
        using <a href="https://p5js.org/" target="_blank">p5.js</a> and <a href="https://en.wikipedia.org/wiki/WebGL" target="_blank">WebGL.</a></p><br>
        <p>50% of proceeds above resting price will be donated to <a href="https://www.givedirectly.org/" target="_blank">GiveDirectly</a>, an organization that directly sends money to people in poverty. Here is the <a href="https://www.charitywatch.org/charities/givedirectly" target="_blank">Charity Watch</a> page for GiveDirectly. I was very impressed by this charity
        and how they are able to allocate a whopping 96% of total funds to their purpose. I hope that in giving a percentage of my project to this organization, families and individuals are able to re-imagine their lives, just like I've done
        with my childhood scribbles.</p><br>
        <h1>Inspiration</h1><br>
        <!-- <p>For as long as I've been a generative artist, I've always been fond of trying to replicate real life. I am particularly gravitated towards replicating traditional mediums in art. For this project, I was inspired by art from our youth. 
            I sought to give homage to the catalyst of artists by imitating the mediums and mannerisms that makes children's art so special. I was inspired by pastel paper, oil pastels, landscape art, and scribbles.</p><br> -->
        <p>For as long as I've been a generative artist, realism through code has captivated me. I've been particularly gravitated towards replicating traditional mediums in art. 
            For this project, I decided to go back to basics and replicate children's art and the mediums and mannerisms that make it so special. 
            Thus, I took heavily from oil pastels, pastel paper, landscape art and scribbles. 
            Something about the art that I would make as a kid gave me a feeling that I've found hard to reobtain. This is an effort to unearth those feelings. I allowed myself to go back to the whimsical, exploratory roots that were the catalyst of my love for making art.</p><br>

        <p>While researching for this project, I came across the name <i>Arcadia</i>. The <a href="https://en.wikipedia.org/wiki/Arcadia_(utopia)" target="_blank">Wikipedia</a> link states <i>Arcadia</i> as <i>"... a vision of pastoralism and harmony with nature. The term is derived from the Greek province of the same name which dates to antiquity; the province's mountainous topography and sparse population of 
            pastoralists later caused the word Arcadia to develop into a poetic byword for an idyllic vision of unspoiled wilderness. Arcadia is a poetic term associated with bountiful natural splendor and harmony."</i> I thought this name was perfect for the project. When scribbling making landscape art, I often
            imagined an ideal place where I would want to hang out in, and I'm sure many other kids did too. For me, the word <i>Arcadia</i> describes that imagination perfectly.</p><br>
        <!-- <div class="imagecontainer">
            <div><img src="../assets/i1.png"></div>
            <div><img src="../assets/i2.png"></div>
            <div><img src="../assets/i3.png"></div>
            <div><img src="../assets/i4.png"></div>
        </div><br> -->
        <h1 id="process">Process</h1><br>
        <p>Here is a look into how some of <i>Arcadia</i> was made. These aren't all of the processes that I used, but they are ones that I thought were the most interesting.</p><br>
        <h1 class="lv2">Paper</h1><br>
        <p>For me, the paper is what brings it all together. It was also the hardest thing to get looking "good". I decided to imitate a very specific type of paper that's commonly
            used with pastel art: Canson Mi-Teintes. The hexagonal pattern of this paper gives a unique feel to the artwork applied to it, and I thought it was perfect for this project.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/miteintes.jpg" class="single"><p class="imgcaption">Canson Mi-Teintes paper</p></div>
        </div><br>
        <p>I created the paper texture in WebGL. First, I started with a hexagonal grid of black and white arcs. I did this in order to give the paper depth.</p><br>
        <div class="imagecontainer">
            <div><img src="../assets/paper1.png" class="single"></div>
        </div><br>
        <p>Then, I added layers of noise. Looks quite jumbled doesn't it?</p><br>
        <div class="imagecontainer">
            <div><img src="../assets/paper2.png"></div>
            <div><img src="../assets/paper3.png"></div>
        </div><br>
        <p>But once softened a bit, the final paper texture emerges. Awesome, now we have some paper to doodle on!</p><br>
        <div class="imagecontainer">
            <div><img src="../assets/paper4.png" class="single"></div>
        </div><br>
        <h1 class="lv2">Mountains</h1><br>
        <p>The mountains are the focal point of the piece. The basis of each mountain
            starts out as a Gaussian curve. Let's put this paper we made to use, shall we?
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/m1.png" class="single"></div>
        </div><br>
        <p>This curve can be manipulated with its standard deviation in order to make it thinner or wider. Special mountains such as valleys use a modified inverse Gaussian curve.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/m2.png"></div>
            <div><img src="../assets/m3.png"></div>
        </div><br>
        <p>After the curve has been set, it is manipulated with some noise to make it more jagged.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/m4.png" class="single"></div>
        </div><br>
        <p>Sweet, we have a squiggly line. But how is the computer supposed to know where the peaks and valleys are in order to separate the mountain faces to make it look like, you know, a mountain? This is achieved by taking every point
            along the line and comparing it to its neighbors. If both of the point's neighbors are below the point, it's a peak. If both of the point's neighbors are above the point, it's a valley.
            These high and low points are then used to chop up the mountain into faces.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/m5.png" class="single"></div>
        </div><br>
        <p>This part's simple. We just make some squiggly lines as before, but vertical. We start the line at a peak/valley and then end it when it hits the ground. These lines have
            a variable called drift, which determines how far they'll veer to the left or right before they hit the ground. It makes for cooler mountains, trust me.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/m6.png" class="single"></div>
        </div><br>
        <p>Now our mountain faces are defined and we can color each face accordingly!
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/m7.png" class="single"></div>
        </div><br>
        <!-- <h1 class="lv2">Paths</h1><br> -->
        <h1 class="lv2">Clouds</h1><br>
        <p>Clouds took a couple of iterations to get right. The first idea was to make a bunch of circles surround a bigger circle and then outline those circles to make a circle-y cloud.
            I felt that this was too circle-y. Go figure. The next iteration was much more fluid, and was less complex! First, I started out with a blob of noise. This was created by
                making a circle with sine and cosine and then modifying the radius with noise.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/c1.png" class="single"></div>
        </div><br>
        <p>The idea is to create a sine wave along the blob. But, the normal sine wave isn't circle-y enough! It's important to strike a balance when it comes to clouds and
            circle-y-ness. So, I spent a while crafting a modified sine wave that looks more like a wave of semi-circles. Sorry, I won't give the formula, family secret.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/c2a.png"><p class="imgcaption">Sine wave</p></div>
            <div><img src="../assets/c2b.png"><p class="imgcaption">Semi-circle wave</p></div>
        </div><br>
        <p>Now we can run the modified sine wave along the blob and voila!
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/c3.png" class="single"></div>
        </div><br>
        <p>This cloud is nice. But, as a cloud gets farther away, you'll notice that the bottom becomes flat. This is no problem to implement because of how we made the noise blob.
            We're able to squish the sine or the cosine portion by multiplying it by a number, which will then squish the top+bottom or left+right of the cloud, respectively. Since we want only the bottom,
            we multiply the sine portion only when the angle is less than pi. And boom, we have variable distance now!
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/c4.png" class="single"></div>
        </div><br>
        <p>When generating the clouds for the artwork, this distance comes into play. Clouds closer to the horizon are flatter and smaller compared to ones farther away, which gives a nice perspective effect. Let's see the result in action!
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/c5.png" class="single"></div>
        </div><br>
        <h1 class="lv2">Scribbles</h1><br>
        <p>Creating scribbles was the most time consuming portion of this project. I went through many iterations, method changes, and assorted mathematical approaches to find something
            that was both fast and believable. The first iteration just stacked a bunch of crooked lines on top of each other to simulate scribbles. Here's an early dev video of that method:
        </p><br>
        <div class="imagecontainer">
            <div><video controls class="single">
                <source src="../assets/745a4f04-c411-5732-b787-fc73d7928ffa_Trim_Trim.mp4" type="video/mp4">
            </video><p class="imgcaption">Early dev video of scribbles </p></div>
        </div><br>
        <p>Although that method looked believable as an end product, I couldn't bear to keep the animation that preceded it. It was too... wiggly and weird. I wanted 
            to make it look like the computer was actually scribbling in a shape in a zig-zag fashion. That meant more iterating was upon me! And learning some new math concepts too...
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/Untitled.png" class="single"><p class="imgcaption">Some math from a very confused artist who has never done trig before</p></div>
        </div><br>
        <p>Finally, I landed on a concept that had everything I needed: speed, believability, and simplicity(ish). First, we take a shape and then put a bounding box around it.
            This box will later determine where the scribbles will be placed.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/s1.png" class="single"></div>
        </div><br>
        <p>Then, we add some horizontal lines to the bounding box. The distance between these lines will determine how filled in a shape will be. Let's keep it loose for the demonstration. We are also able to rotate this box to get the scribble angle. Let's do a tidy 45 degrees.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/s2.png" class="single"></div>
        </div><br>
        <p>Next, we find where each line intersects with the shape and place a point at each intersection. If the number of points on a line is even, then we keep it. The rest we discard.
            We do this because a line will always go <i>into</i> and then go <i>out of</i> a shape an even number of times. We will use these points as "anchor points" for our scribbles.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/s3.png" class="single"></div>
        </div><br>
        <p>Now, we need to separate each point pair to create a right and left side. We can get rid of the bounding box as well.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/s4.png" class="single"></div>
        </div><br>
        <p>Then comes the fun part. Since we have all our points defined and split up, we can just alternate between right and left points to mimic a zig-zag motion.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/s5.png" class="single"></div>
        </div><br>
        <p>And there's our scribbly shape! I've added a second picture to show what the shape would look like if it was filled in a bit more.
        </p><br>
        <div class="imagecontainer">
            <div><img src="../assets/s6.png"></div>
            <div><img src="../assets/s7.png"></div>
        </div><br>
        <h1 id="features">Features</h1><br>
        <h1 class="lv2">Palette</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/p1.png"><p class="imgcaption">Bird Dog</p></div>
            <div><img src="../assets/p1a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p2.png"><p class="imgcaption">Ice Palace</p></div>
            <div><img src="../assets/p2a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p3.png"><p class="imgcaption">Old Salt</p></div>
            <div><img src="../assets/p3a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p4.png"><p class="imgcaption">Springtide</p></div>
            <div><img src="../assets/p4a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p5.png"><p class="imgcaption">Lapland</p></div>
            <div><img src="../assets/p5a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p6.png"><p class="imgcaption">Rockies</p></div>
            <div><img src="../assets/p6a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p7.png"><p class="imgcaption">Plum</p></div>
            <div><img src="../assets/p7a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p8.png"><p class="imgcaption">Evergreen</p></div>
            <div><img src="../assets/p8a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p9.png"><p class="imgcaption">Playdate</p></div>
            <div><img src="../assets/p9a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p10.png"><p class="imgcaption">Diner</p></div>
            <div><img src="../assets/p10a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p11.png"><p class="imgcaption">Extraterrestrial</p></div>
            <div><img src="../assets/p11a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p12.png"><p class="imgcaption">Inferno</p></div>
            <div><img src="../assets/p12a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p13.png"><p class="imgcaption">Crystalline</p></div>
            <div><img src="../assets/p13a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p14.png"><p class="imgcaption">Woodblock</p></div>
            <div><img src="../assets/p14a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p15.png"><p class="imgcaption">Monochrome</p></div>
            <div><img src="../assets/p15a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/p16.png"><p class="imgcaption">Fortune Fish</p></div>
            <div><img src="../assets/p16a.png"><p class="imgcaption">Alternate Paper Color</p></div>
        </div><br>
        <h1 class="lv2">Border</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/b1.png"><p class="imgcaption">Normal</p></div>
            <div><img src="../assets/b2.png"><p class="imgcaption">None</p></div>
            <div><img src="../assets/b3.png"><p class="imgcaption">Thick</p></div>
        </div><br>
        <h1 class="lv2">Scribble pressure</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/lowpress.png"><p class="imgcaption">Low</p></div>
            <div><img src="../assets/medpress.png"><p class="imgcaption">Medium</p></div>
            <div><img src="../assets/highpress.png"><p class="imgcaption">High</p></div>
        </div><br>
        <h1 class="lv2">Scribble roughness</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/lowr.png"><p class="imgcaption">Low</p></div>
            <div><img src="../assets/medr.png"><p class="imgcaption">Medium</p></div>
            <div><img src="../assets/highr.png"><p class="imgcaption">High</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/vhighr.png"><p class="imgcaption">Very High</p></div>
            <div><img src="../assets/vr.png"><p class="imgcaption">Varied</p></div>
        </div><br>
        <h1 class="lv2">Scribble type</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/randscrib.png"><p class="imgcaption">Random</p></div>
            <div><img src="../assets/neatscrib.png"><p class="imgcaption">Neat</p></div>
        </div><br>
        <h1 class="lv2">Mountain type</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/normalm.png"><p class="imgcaption">Normal</p></div>
            <div><img src="../assets/valley.png"><p class="imgcaption">Valley</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/plateau.png"><p class="imgcaption">Plateau</p></div>
            <div><img src="../assets/volcano.png"><p class="imgcaption">Volcano</p></div>
        </div><br>
        <h1 class="lv2">Mountain jaggedness</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/j1.png"><p class="imgcaption">Very Low</p></div>
            <div><img src="../assets/j2.png"><p class="imgcaption">Low</p></div>
            <div><img src="../assets/j3.png"><p class="imgcaption">Medium</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/j4.png"><p class="imgcaption">High</p></div>
            <div><img src="../assets/j5.png"><p class="imgcaption">Very High</p></div>
        </div><br>
        <h1 class="lv2">Mountain height</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/lowh.png"><p class="imgcaption">Short</p></div>
            <div><img src="../assets/medh.png"><p class="imgcaption">Medium</p></div>
            <div><img src="../assets/highh.png"><p class="imgcaption">Tall</p></div>
        </div><br>
        <h1 class="lv2">Land type</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/normall.png"><p class="imgcaption">Normal</p></div>
            <div><img src="../assets/path.png"><p class="imgcaption">Path</p></div>
            <div><img src="../assets/creek.png"><p class="imgcaption">Creek</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/lake.png"><p class="imgcaption">Lake</p></div>
            <div><img src="../assets/road.png"><p class="imgcaption">Road</p></div>
        </div><br>
        <h1 class="lv2">Sun type</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/normalsun.png"><p class="imgcaption">Normal</p></div>
            <div><img src="../assets/moon.png"><p class="imgcaption">Moon</p></div>
            <div><img src="../assets/eclipse.png"><p class="imgcaption">Eclipse</p></div>
            <div><img src="../assets/normalsunlines.png"><p class="imgcaption">Normal with sun lines</p></div>
            <div><img src="../assets/eclipsesunlines.png"><p class="imgcaption">Eclipse with sun lines</p></div>
        </div><br>
        <h1 class="lv2">Cloud type</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/cumulus.png"><p class="imgcaption">Cumulus</p></div>
            <div><img src="../assets/stratus.png"><p class="imgcaption">Stratus</p></div>
            <div><img src="../assets/altocumulus.png"><p class="imgcaption">Altocumulus</p></div>
        </div><br>
        <h1 class="lv2">Cloud density</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/singlecloud.png"><p class="imgcaption">Single Cloud</p></div>
            <div><img src="../assets/cd1.png"><p class="imgcaption">Low</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/cd2.png"><p class="imgcaption">Medium</p></div>
            <div><img src="../assets/cd3.png"><p class="imgcaption">High</p></div>
        </div><br>
        <h1 class="lv2">Rock Density</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/lowrd.png"><p class="imgcaption">Low</p></div>
            <div><img src="../assets/medrd.png"><p class="imgcaption">Medium</p></div>
            <div><img src="../assets/highrd.png"><p class="imgcaption">High</p></div>
        </div><br>
        <h1 class="lv2">Rock Size</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/smallr.png"><p class="imgcaption">Small</p></div>
            <div><img src="../assets/medrock.png"><p class="imgcaption">Medium</p></div>
            <div><img src="../assets/bigr.png"><p class="imgcaption">Big</p></div>
        </div><br>
        <h1 class="lv2">Grass Density</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/lowg.png"><p class="imgcaption">Low</p></div>
            <div><img src="../assets/medg.png"><p class="imgcaption">Medium</p></div>
            <div><img src="../assets/highg.png"><p class="imgcaption">High</p></div>
        </div><br>
        <h1 class="lv2">Grass Height</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/shortgh.png"><p class="imgcaption">Short</p></div>
            <div><img src="../assets/medgh.png"><p class="imgcaption">Medium</p></div>
            <div><img src="../assets/tallgh.png"><p class="imgcaption">Tall</p></div>
        </div><br>
        <h1 id="evolution">Evolution</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/1.png"><p class="imgcaption">Mountain shape</p></div>
            <div><img src="../assets/2.png"><p class="imgcaption">First texture</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/3.png"><p class="imgcaption">Experimenting with depth and paper showing through</p></div>
            <div><img src="../assets/4.png"><p class="imgcaption">First scribble and grass iteration</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/6.png"><p class="imgcaption">First paper texture iteration and experimenting with features</p></div>
            <div><img src="../assets/7.png"><p class="imgcaption">New scribble iteration and refining features</p></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/a1.png"><p class="imgcaption">Final</p></div>
        </div><br>
    </div><br><br>
</template>

<style scoped>
h2 {
    font-family: 'Walter Turncoat';
    font-size:90px;
}
h1 {
    font-family: 'Walter Turncoat';
    font-size:30px;
}
.lv2 {
    font-size:22px;
}

@media screen and (max-width: 1700px) {
    h2 {
        font-size:75px;
    }
}
@media screen and (max-width: 1480px) {
    h2 {
        font-size:72px;
    }
}
@media screen and (max-width: 1300px) {
    h2 {
        font-size:65px;
    }
}
@media screen and (max-width: 1100px) {
    h2 {
        margin-left:100px;
        margin-right:100px;
    }
}
@media screen and (max-width: 500px) {
    h2 {
        font-size:60px;
        margin-left:30px;
        margin-right:30px;
    }
}
</style>